import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

function Error() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="d--flex j--center a-item--center " style={{"height": "80%"}}>
        <h1 style={{borderRight: "1px solid black"}} className="pr--0-5">404</h1>
        <h2 className="pl--0-5">This page could not be found</h2>
      </div>
    </Layout>
  )
}

export default Error